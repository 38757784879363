import parse from "date-fns/parse";
import { List, Map, Set } from "immutable";
import pluralize from "pluralize";
import { useMemo } from "react";

import EntityLink from "components/Entities/EntityLink";
import { useEntityFromTimeline } from "components/Timeline/useEntityFromTimeline";

import { entityNames } from "constants/entity";
import { selectEntity } from "selectors/entity";
import commaList from "utils/text/commaList";
import indefiniteArticle from "utils/text/indefiniteArticle";

import useLoggedMemo from "hooks/useLoggedMemo";
import useReduxState from "hooks/useReduxState";

export const getUniqueObjectsByType = (activities, uniqueBy = "object") =>
  activities.reduce((byType, act) => {
    const actObject = act.get(uniqueBy);
    const objectType = actObject.split(":")[0];

    if (!byType.has(objectType)) {
      return byType.set(objectType, List([act]));
    }
    if (
      !byType
        .get(objectType)
        .find((activity) => activity.get(uniqueBy) === actObject)
    ) {
      return byType.set(objectType, byType.get(objectType).push(act));
    }

    return byType;
  }, Map());

const getCount = (activities, counts, config, showCounts) => {
  const { key, displayName, verb, pre } =
    typeof config === "string" ? { key: config } : config;
  const activityKey = verb || key;
  const name = displayName || entityNames[key] || "item";

  if (activities.get(activityKey) && activities.get(activityKey).size > 0) {
    if (counts.length === 0 && activities.get(activityKey).size === 1) {
      return [...counts, `${pre || indefiniteArticle(name)} ${name}`];
    }
    if (activities.get(activityKey).size === 1) {
      return [...counts, `${indefiniteArticle(name)} ${name}`];
    }

    return [
      ...counts,
      pluralize(name, activities.get(activityKey).size, showCounts),
    ];
  }

  return counts;
};

export const getEntityCountString = (
  activities,
  countConfigs = undefined,
  showCounts = true
) => {
  const counts = (countConfigs || activities.keySeq()).reduce(
    (newCounts, key, index) =>
      getCount(activities, newCounts, key, showCounts, index),
    []
  );

  return commaList(counts, (v) => v, { commaFunc: (i, str) => str });
};

export const getUniqueEntityCountString = (
  activities,
  countConfigs = undefined,
  uniqueBy = "object",
  showCounts = true
) => {
  const uniqueActivities = getUniqueObjectsByType(activities, uniqueBy);

  return getEntityCountString(uniqueActivities, countConfigs, showCounts);
};

export const ACTIVITY_KEYS = ["object", "origin", "actor"];

const updateParams = (next) => [List(), (aggr) => aggr.push(next)];
const getSortByName = (key) =>
  `activitiesBy${key.charAt(0).toUpperCase()}${key.substring(1, key.length)}`;
const getSortByTypeName = (key) => `${getSortByName(key)}Type`;

export const updateSortBy = (key, agg, next) => {
  const byName = getSortByName(key);
  const byTypeName = getSortByTypeName(key);

  if (!next.get(key)) {
    return {
      [byName]: agg[byName] || Map(),
      [byTypeName]: agg[byTypeName] || Map(),
    };
  }

  return {
    [byName]: (agg[byName] || Map()).update(
      next.get(key),
      ...updateParams(next)
    ),
    [byTypeName]: (agg[byTypeName] || Map()).update(
      next.get(key).split(":")[0],
      ...updateParams(next)
    ),
  };
};

export const sortActivities = (activities) =>
  (activities || List([])).reduce(
    (agg, next) =>
      [...ACTIVITY_KEYS, "verb"].reduce(
        (newActivities, key) => ({
          ...newActivities,
          ...updateSortBy(key, agg, next),
        }),
        {}
      ),
    {}
  );

export const useActivitiesState = (passedActivities, sortedActivities) => {
  const activities = passedActivities || List([]);
  // const multipleEpisodesOfSamePodcast = podcast && first_entity_type === 'episode' && sortedActivites.activitiesByObjectType.size === 1;
  const state = {};

  if (passedActivities) {
    for (let i = 0; i < ACTIVITY_KEYS.length; i += 1) {
      const key = ACTIVITY_KEYS[i];
      const byName = getSortByName(key);
      const byTypeName = getSortByTypeName(key);
      const capitalizedKey = `${key.charAt(0).toUpperCase()}${key.substring(
        1,
        key.length
      )}`;
      const [firstKeyType, firstKeyId] = activities.first().get(key)
        ? activities.first().get(key).split(":")
        : [null, null];

      state[`hasOne${capitalizedKey}`] = sortedActivities[byName].size === 1;
      state[`hasOne${capitalizedKey}Type`] =
        sortedActivities[byTypeName].size === 1;
      state[`first${capitalizedKey}`] = activities.first().get(`${key}_entity`);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      state[`first${capitalizedKey}`] = useEntityFromTimeline(
        activities.first().get(key)
      ).entity;
      state[`first${capitalizedKey}Type`] = firstKeyType;
      state[`first${capitalizedKey}Id`] = firstKeyId;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => state, [activities, sortedActivities]);
};

export const useAggregate = (group) => {
  const activities = group.get("activities");

  const sortedActivities = useMemo(
    () => sortActivities(activities),
    [activities]
  );
  const activitiesState = useActivitiesState(activities, sortedActivities);

  return useMemo(
    () => ({
      ...sortedActivities,
      rawActivities: activities,
      activities,
      activitiesState,
      group,
      activityDate: parse(group.get("updated_at")),
    }),
    [activities, activitiesState, group, sortedActivities]
  );
};

export const allActivitiesEpisodeOfTheSamePodcast = (
  activities,
  activitiesAreSortedByType = false
) => {
  const areAllEpisodes = activitiesAreSortedByType
    ? activities.has("episode") && activities.size === 1
    : activities.every(
        (act) =>
          act.get("object") && act.get("object").split(":")[0] === "episode"
      );
  let allEpisodesOfSamePodcast = null;
  let firstPodcastId = null;

  if (areAllEpisodes) {
    const episodeActivities = activitiesAreSortedByType
      ? activities.get("episode")
      : activities;

    firstPodcastId = episodeActivities.getIn([0, "podcast_id"]);
    allEpisodesOfSamePodcast = episodeActivities.every(
      (activity) => activity.get("podcast_id") === firstPodcastId
    );
  }

  return areAllEpisodes && allEpisodesOfSamePodcast && firstPodcastId
    ? firstPodcastId
    : null;
};

export const getShownByField = (aggregateProps) => {
  if (
    aggregateProps.activitiesState.hasOneObject &&
    !aggregateProps.activitiesState.hasOneActor
  ) {
    return "actor";
  }

  return "object";
};

export const getActivitiesByActorOrObjectType = (aggregateProps) => {
  if (
    aggregateProps.activitiesState.hasOneObject &&
    !aggregateProps.activitiesState.hasOneActor
  ) {
    return aggregateProps.activitiesByActor
      .reduce((filteredActors, actors, key) => {
        const actor_type = key.split(":")[0];

        if (actor_type === "user" || actor_type === "creator") {
          return filteredActors.set(key, List([actors.get(0)]));
        }

        return filteredActors;
      }, Map())
      .reduce((newList, activities) => newList.merge(activities), List());
  }

  return aggregateProps.activitiesByObjectType
    .reduce((filteredObjectTypes, objects, type) => {
      const pushed = [];

      const filteredObjects = objects.filter((object) => {
        const included = pushed.includes(object.get("object"));

        pushed.push(object.get("object"));

        return !included;
      });

      return filteredObjectTypes.set(type, filteredObjects);
    }, Map())
    .reduce((newList, activities) => newList.merge(activities), List());
};

export const useAggregateEntities = (activities, byType = false) => {
  const aggregatedEntities = useReduxState(
    (state) => {
      const newEntities = activities.reduce((passedEntities, activity) => {
        let entities = passedEntities;

        const ids = [
          activity.get("object"),
          activity.get("actor"),
          { entity_type: "podcast", entity_id: activity.get("podcast_id") },
        ];

        ids.forEach((entity) => {
          if (
            entity &&
            (typeof entity === "string" ? entity : entity.entity_id)
          ) {
            const isString = typeof entity === "string";
            const { entity_type, entity_id } = isString
              ? {
                  entity_type: entity.split(":")[0],
                  entity_id: entity.split(":")[1],
                }
              : entity;
            const key = `${entity_type}:${entity_id}`;

            if (!entities.has(key)) {
              entities = entities.set(
                key,
                selectEntity(state, entity_id, entity_type)
              );
            }
          }
        });

        return entities;
      }, Map());

      if (byType) {
        return newEntities.reduce((entitiesByType, entity, entity_Key) => {
          const [entity_type, entity_id] = entity_Key.split(":");

          return {
            ...entitiesByType,
            [entity_type]: {
              ...(entitiesByType[entity_type] || {}),
              [entity_id]: entity,
            },
          };
        }, {});
      }

      return newEntities;
    },
    [activities, byType]
  );

  return useLoggedMemo(
    () => aggregatedEntities,
    // only change if activities are different
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activities, byType],
    "aggregateEntities"
  );
};

export const getUniqueFields = (activities, uniqueBy = "object") =>
  activities.reduce((fields, act) => fields.add(act.get(uniqueBy)), Set());

export const getAggregateEntity = (entities, entity_type, entity_id) =>
  entities.get(`${entity_type}:${entity_id}`);

export const getAggregateEntities = (entities) =>
  entities.map((ent) => {
    const [entity_type, entity_id] = ent.split(":");

    return getAggregateEntity(entities, entity_type, entity_id);
  });

export const getActorLinks = (actors) => {
  const actorsCount = actors.length || actors.size;
  const sliceAt = actorsCount === 3 ? 3 : 2;
  const remainingMarker = "addRemainingCount";
  const slicedActors =
    actorsCount > sliceAt
      ? [...actors.slice(0, sliceAt), remainingMarker]
      : actors;

  return commaList(slicedActors, (actor) => {
    if (actor === remainingMarker) {
      const remainingActorsCount = actorsCount - sliceAt;

      return `${remainingActorsCount} ${pluralize(
        "other",
        remainingActorsCount
      )}`;
    }
    if (actor) {
      const [actor_type, actor_id] = actor.split(":");

      return (
        <EntityLink
          key={actor_id}
          entity_type={actor_type}
          entity_id={actor_id}
        />
      );
    }

    return "";
  });
};

export const getEntityTypeCounts = (objects) => {
  const objectCounts = objects.reduce((counts, object) => {
    const [entity_type] = object.split(":");

    if (!counts[entity_type]) {
      return { ...counts, [entity_type]: 1 };
    }

    return { ...counts, [entity_type]: counts[entity_type] + 1 };
  }, {});

  return commaList(Object.keys(objectCounts), (key) =>
    pluralize(entityNames[key], objectCounts[key], true)
  );
};

export const groupActivities = (
  activities,
  field,
  fieldTypesMustMatch = null
) =>
  activities.reduce(
    ({ grouped, ungrouped }, activity) => {
      const found = ungrouped.filter((act) => {
        if (fieldTypesMustMatch) {
          return (
            act.get(field) === activity.get(field) &&
            activity.get(fieldTypesMustMatch) &&
            act.get(fieldTypesMustMatch) &&
            activity.get(fieldTypesMustMatch).split(":")[0] ===
              act.get(fieldTypesMustMatch).split(":")[0]
          );
        }

        return act.get(field) === activity.get(field);
      });

      if (found.length > 1) {
        const foundIds = found.map((act) => act.get("id"));

        return {
          grouped: [...grouped, { activities: List(found), groupedBy: field }],
          ungrouped: ungrouped.filter(
            (act) => !foundIds.includes(act.get("id"))
          ),
        };
      }

      return {
        grouped,
        ungrouped,
      };
    },
    { grouped: [], ungrouped: activities }
  );

export const hideActivity = (entities) => {
  if (entities) {
    return Object.entries(entities).some(([entity_type, entity]) => {
      if (
        entity_type === "userlist" &&
        entity &&
        entity.get("privacy") !== "public"
      ) {
        return true;
      }

      return false;
    });
  }

  return false;
};
